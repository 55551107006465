@import '~bootstrap/scss/mixins';

@import "~bootstrap/scss/mixins/_breakpoints";

// define shadows and reusable mixin here

@mixin shadow() {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15) !important;
}

