$primary: #c77b21;
// $primary-25: #0661ff3f;
$primary-light: rgb(206, 141, 88);
$text-secondary: #636363;
$secondary-light: #989898;
$success: #28cc0f;
$success-25: #28cc0f3f;
$font-family-base: "Montserrat";
$gray: #6c6c6c;
$purple: #bd3ffb;
$snowWhite: #F9F9F9;
$pastelGray: #CCCCCC;
$greenWhite: #EAEAEA;
$danger: #f23f3f;
$light-gray: #f6f7fa;
$white: #fff;
$lightRed: #F4E5E8;
$white-25: #ffffff3f;
$gray-50: #6b696980;
$mail-bg: #2a4edf08;
$light-text: #707070;
$hover-light: #fafafa;
$silverColor: #F6F7FA;
$lightBorder: #EFEFEF;
$lightGray: #D3D3D3;
$lavendarGray: #E1E1E1;
$lightBlack: #F5F5F5;
// $text-muted: #787377; 
$bg-body: #f1f1f1;
$fireBrick: #CC2200;
//custom colors
$text-color: #585558;
$table-h-text: #8d8d8d;
$table-h-bg: #f8f8f8;
// $bg-body: #f1f1f1;
$aliceblue: #F3F6FF;
$light-blue: #f8f9fe;
$yellow: #ffa810;

// font-size
$font-size-base: 0.8rem;
$lead-font-size: 1rem;
// Body
$body-color: #808080;
// Headings
$headings-margin-bottom: 0.4rem;
$headings-font-family: $font-family-base;
$headings-font-weight: 600;

$headings-line-height: normal;
$headings-color: $primary;

//pagination
$pagination-active-bg: $gray-50;

// FormView
$form-label-color: $text-secondary;
$form-label-margin-bottom: 0rem;
$form-check-label-color: $text-secondary;

// Input
$input-placeholder-color: #e8e8e8;
$input-color: $text-secondary;
$input-btn-padding-y: 0.7rem;
$input-btn-focus-box-shadow: 0;
$input-focus-bg: #9bc0ff14;
$input-font-size: $font-size-base;
$input-disabled: $light-text;
// $input-border-color: #ebeaeb;
// $input-plaintext-color: $text-muted;
// Buttons
$btn-padding-x: 0.8rem;
$btn-padding-y: 0.7rem;

// HR
$hr-color: $text-secondary;
$hr-margin-y: 0;
$hr-height: 0.5px;

// allow negative margin
$enable-negative-margins: true;

$theme-colors: (
  "primary": $primary,
  "secondary": $text-secondary,
  "gray": $gray,
  "success": $success,
  "danger": $danger,
  "light-gray": $light-gray,
  "white": $white,
  "bgBody": $bg-body,
  "lightGray": $text-color,
  "lightBlue": $light-blue,
  "bgYellow": $yellow,
  "secondaryLight": $secondary-light,
  "bgSilver" : $silverColor,
  'fireBrick': $fireBrick,
  'lightBorder':$lightBorder,
  'light-Gray':$lightGray,
  'aliceblue':$aliceblue,
  'pastelGray':$pastelGray,
  'greenWhite':$greenWhite,
  'snowWhite':$snowWhite,
  'lavendarGray':$lavendarGray,
  'lightBlack':$lightBlack,
  'lightRed':$lightRed
);