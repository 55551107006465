@charset "UTF-8";

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: url('../Fonts/Montserrat.woff') format('woff'),
   url('../Fonts/Montserrat.ttf') format('ttf'),
   url('../Fonts/Montserrat.eot') format('eot'),
}

@font-face {
  font-family: 'Montserrat Regular';
  font-weight: 400;
  src: url('../Fonts/Montserrat\ Regular.woff') format('woff'),
   url('../Fonts/Montserrat\ Regular.ttf') format('ttf'),
   url('../Fonts/Montserrat\ Regular.eot') format('eot'),
}

// @font-face {
//   font-family: 'Montserrat Medium';
//   font-weight: 500;
//   src: url('../Fonts/Montserrat-Medium.ttf') format('ttf'),
// }

@font-face {
  font-family: 'Montserrat Bold';
  font-weight: 600;
  src: url('../Fonts/Montserrat-Bold.woff') format('woff'), 
    url('../Fonts/Montserrat-Bold.ttf') format('ttf'),
    url('../Fonts/Montserrat-Bold.eot') format('eot'),
}