// @import '../../../assets/styles/variables';
// @import '../../../assets/styles/mixins';
@import '../../Assets/Styles/variables';
@import '../../Assets/Styles/mixins';

.custom-drop-down {
  .menu-toggle {
    background-color: $primary-light;
    border: none !important;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: $gray;
    // padding: 10px;
    border-radius: 7px;
    margin-left: -20px;

    &.kanban {
      margin-right: -20px;
    }
    &.popup{
      margin-left:0px;
      padding:0px;
    }

    &:active,
    &:focus {
      box-shadow: none !important;
    }

    &:focus {
      outline: none;

    }

    &:hover {
      color: $primary;
    }

    &:after {
      display: none !important;
    }

    .show > .btn-primary .dropdown-toggle {
      background-color: $white;
      color: $text-color !important;
      border: none;
    
    }
  }

  .menu-dropdown{
    border: 0 !important;
    padding: 0.5rem !important;
    margin: 4px 0;
    border-radius: 8px;
    @include shadow();
    // inset: 20px 0 auto -50px !important;

    &.trigger { 
      inset:  -10px auto auto -40px !important;
      .dropdown-item {
        padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
      }

      &:before {
        top: 0%;
      }
    }

    &.kanban {
      margin-right: -30px;
    }
    
    &.page {
      margin-top: 30px;
      margin-right: -40px;
    }

    &.mr-n12 {
      inset: 10px auto auto 10px !important;
    }

    .dropdown-divider {
      margin: 0;
      color: $light-text !important;
      border-color: $light-text;
      opacity: 0.1;
    }
    .dropdown-item1{
      padding: 0px 1rem 1rem 8px !important;
      display: flex;
      align-items: center;
      font-weight: 600;
      color: $text-color;
      font-size: 12px;

      &:active {
        background-color: $hover-light !important;
        border-color: $hover-light !important;
      }

      &:focus {
        box-shadow: none !important;
      }

      &:hover {
        border-radius: 10px;
        background-color: $hover-light !important;
      }
    }

    .dropDowntext {
      &:hover {
        color: $primary;
      }
    }
    .dropdown-item {
      padding: 1rem 1rem 1rem 1rem !important;
      display: flex;
      align-items: center;
      font-weight: 600;
      color: $text-color;
      font-size: 12px;

      &:active {
        background-color: $hover-light !important;
        border-color: $hover-light !important;
      }

      &:focus {
        box-shadow: none !important;
      }

      &:hover {
        border-radius: 10px;
        background-color: $hover-light !important;
      }
    }

    // &:before {
    //   right: 10%;
    //   top: -5%;
    //   content: ' ';
    //   height: 0;
    //   width: 0;
    //   position: absolute;
    //   pointer-events: none;
    //   border: 10px solid rgba(255, 255, 255, 0);
    //   border-bottom-color: #ffffff;
    //   margin-top: -5px;
    //   background-color: red;
    // }

    .dropDowntext {
      &:hover {
        color: $primary;
      }
    }

    
  }
  .leftMenu{
    @extend .menu-dropdown;
    transform: translate3d(150px, 50px, 0px) !important;
    &:before {
      left: 10% !important;
    }
    
  }

  .btn:first-child:active{
    color: $primary;
  }


  &.show {
    // .menu-dropdown {
    // border-top-right-radius: 0px;
    // }
    

    .menu-toggle {
      background-color: $primary-light;
    }
  }
}
