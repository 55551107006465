@import "../../Assets/Styles/variables";
@import "../../Assets/Styles/mixins";

.pagination__container {
  list-style: none;
  display: flex;
  align-items: stretch;
  margin: 20px 0;
  padding: 0;
  font-weight: 600;

  li {
    display: flex;
    @media (max-width: 576px) {
      &:not(:first-child):not(:last-child) {
        display: none;
      }
    }
  }

  &.circle {
    align-items: center;

    li {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .previous,
    .next {
      width: auto;
      margin: 0 5px;

      a {
        outline: none !important;
      }
    }
  }
}

li.break {
  padding: 2px;
  margin: 0 3px;
  color: $text-color;
}

.pagination__page {
  padding: 6px 12px;
  margin: 0 3px;
  color: $text-color;
  border-radius: 8px;
  text-decoration: none;
  font-size: 12px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid $primary-light;

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
    // background: $primary;
    // color: $white;
  }
}

.pagination__page-prev-next {
  margin: 0 5px;
  border-radius: 5px;

  a {
    @extend .pagination__page;
    margin: 0;
    background-color: #F9F9F9;
    color: $headings-color;
  }

  &.disabled {
    a {
      cursor: not-allowed;
      background-color: #F9F9F9;
      color: $white;
    }
    &:hover {
      & svg path {
        stroke: rgb(108, 108, 108);
      }
    }
  }

  // &:hover {
  //   & svg path {
  //     stroke: white;
  //   }
  // }
}

.pagination__page-circle {
  @extend .pagination__page;
  background: $primary;
  border-radius: 50%;
  padding: 0;
  margin: 0;

  &:hover {
    background: $bg-body;
    color: $text-color;
  }
}

.pagination__page-active {
  background:#C77B21 !important;
  color: $white;
}

.pagination__page-circle-active {
  background: $bg-body;
  color: $text-color;
}

.pagination__pages-showing {
  color: #b2aaaa;
  font-size: 12px;
}

.pagination__page-size {
  background-color: $text-color !important;
  color: $text-color !important;
  font-size: 12px !important;
}

.pagination__per-page {
  display: flex;
  align-items: center;

  .text {
    font-size: 12px;
    color: $text-color;
    font-weight: 600;
    margin-right: 10px;
  }

  .menu-toggle {
    background-color: $bg-body !important;
  }
}
